import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { lazy, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import {
  routeFactory as appRouteFactory,
  Error404,
  SiteContextProvider,
  SuspenseWrapper,
} from '@volvo/vce-package-site-applicationlayout';
import {
  AuthGuard,
  AuthProvider,
  ConfigProvider,
  ErrorBoundary,
  InternalError,
  LoginPage,
  LoginType,
  themes,
  UikitApp,
} from '@volvo/vce-uikit';
import i18n from '../i18n';
import { routeFactory, routes } from '../routing';
import { Layout } from './Layout';

// import '../../wdyr';

loadDevMessages();
loadErrorMessages();

const AdminPage = lazy(() => import('@/dev/pages/admin/Admin'));

const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary errorContent={<InternalError />}>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </ErrorBoundary>
    ),
    children: [
      {
        path: appRouteFactory.appRoot(),
        element: (
          <AuthGuard>
            <SiteContextProvider
              environment={import.meta.env.VITE_API_ENV}
              startPath={routeFactory.siteMap.overview(':siteId', ':companyId')}
            >
              <Layout>
                <Outlet />
              </Layout>
            </SiteContextProvider>
          </AuthGuard>
        ),
        children: [
          ...routes,
          {
            path: routeFactory.admin.overview(':siteId', ':companyId'),
            element: (
              <SuspenseWrapper>
                <AdminPage />
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: appRouteFactory.login(),
        element: <LoginPage loginTypes={[LoginType.Credentials, LoginType.OpenIdConnect]} />,
      },
      {
        path: appRouteFactory.all(),
        element: <Error404 />,
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <ConfigProvider envMode={import.meta.env.VITE_API_ENV}>
      <UikitApp theme={themes['volvo-light']} i18n={i18n}>
        <RouterProvider router={router} />
      </UikitApp>
    </ConfigProvider>
  </StrictMode>,
);
