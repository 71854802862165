import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppLayout,
  CurrentView,
  SideMenuItemSeparator,
  useSiteContext,
} from '@volvo/vce-package-site-applicationlayout';
import type { SystemIconId } from '@volvo/vce-uikit';
import { routeFactory } from '@/routing';
import { useMenuItems } from '../helpers/hooks/useMenuItems';

type LayoutProps = {
  children: ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const { selectedSite, selectedCompany } = useSiteContext();

  const mapMenuItems = [
    ...useMenuItems(),
    {
      key: 'admin',
      text: t('navigation:admin'),
      icon: 'toolbox-settings' as SystemIconId,
      href: routeFactory.admin.overview(selectedSite.id, selectedCompany.id),
      requirements: {
        view: CurrentView.SITE,
      },
      separator: SideMenuItemSeparator.BEFORE,
    },
  ];

  return (
    <AppLayout menuItems={mapMenuItems} appName="Site map">
      {children}
    </AppLayout>
  );
};
